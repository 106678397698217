import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import FeaturesRow from "../../components/pages/features/row"
import BannerComponent from "../../components/pages/features/banner"
import FeaturesComponent from "../../components/pages/main/landing-features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeatureAccordion from "../../components/pages/main/accordion"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const mobileAccordionData = [
  {
    name: "improve",
    title: "Как использование CRM с мобильной версией помогает улучшить клиентский сервис?",
    content: `
      <p>Во время общения с клиентами педагог или администратор может быстро зайти с
      телефона или планшета в CRM, найти нужного ученика и построить грамотную консультацию. Посмотреть оценки, комментарии о поведении ребенка и его успехах. Ваш клиентский сервис перейдет на новый уровень вместе с использованием мобильной версии CRM.
      </p>
    `,
  },
  {
    name: "mobileForClients",
    title: "Есть ли у CRM мобильная версия для использования клиентами?",
    content: `
      <p>Возьмите максимум от использования CRM с мобильной версией и подключите Личные кабинеты учеников. Это отдельный кабинет для каждого клиента, где есть вся необходимая информация. Ученик или родитель может зайти в свой кабинет с любого устройства, так как мобильная версия системы адаптирована для личных кабинетов. В кабинете клиент может пополнить с телефона свой денежный баланс для оплаты услуг и абонементов, просмотреть расписание, домашние задания, статус посещений занятий и историю оплат.</p>
    `,
  }
]

const MobileFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Мобильная версия', url: ''}
  ];
  return (
    <Layout page={"mobile"}>
      <SEO
        title="Попробуйте мобильную версию CRM-системы Параплан"
        description="Используйте мобильную CRM для эффективного управления бизнесом со смартфона. Общайтесь с клиентами, ставьте задачи, где бы вы ни находились."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"mobile"}
        htmlTitle='Работайте <br/> с телефона'
        htmlDescription='Развивайте свой бизнес, где бы вы ни находились. <br/> С помощью мобильной CRM Параплан вы можете <br/> эффективно решать задачи даже на ходу.'
        color='#6D20D2'
        image={require("../../images/cover/mobile.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
      <AboutComponent
        title='Информация <br/> на кончиках пальцев'
        description='Мобильная версия Параплан CRM адаптирована под любое устройство. Просматривайте с браузера телефона или планшета всю важную информацию: от продаж и роста количества клиентов до эффективности ваших направлений.'
        image={require("../../images/screenshot/mobile/mobile_03@2x.png")}
        imageWidth={565}
        imageHeight={535}
        bgSize={"skewed-left"}
        reverse={true}
        color='#319DED' />
      <AboutComponent
        title='Оставайтесь на связи <br/> с клиентами'
        description='Переписывайтесь с клиентами в мессенджерах сразу из CRM-системы, даже
        если вы не у компьютера в офисе. Зайдите в мобильную версию Параплан CRM, откройте внутренний чат и ведите общение с учениками как обычно. На своем телефоне вам не придется переключаться между личным и рабочим аккаунтом WhatsApp или Telegram.'
        image={require("../../images/screenshot/mobile/mobile_04@2x.png")}
        imageWidth={513}
        imageHeight={535}
        bgSize={"skewed-right"}
        reverse={false}
        color='#03C4A4' />
      {/*<FreeSummer topInd="20"/>*/}
      <Discount />
      <AboutComponent
        containerStyle={{ marginTop: 20 }}
        title='Управляйте компанией <br/> на ходу'
        image={require("../../images/screenshot/mobile/mobile_02@2x.png")}
        description={"Вам не нужно быть в офисе, чтобы следить за бизнесом. Подтверждайте запись, ставьте напоминания, проверяйте выполненные задачи, просматривайте отчетность даже на ходу. Все данные собраны в одном месте, упорядочены и единообразны. Важные детали не будут упущены."}
        imageWidth={563}
        imageHeight={501}
        bgSize={"skewed-right"}
        reverse={true}
        color='#E65D49' />
      <FeaturesRow
        title={"Будьте в движении, продавайте больше"}
        features={[
          {
            name: "Получайте мгновенные уведомления",
            text: "Оставайтесь в курсе дел ваших клиентов и сотрудников с моментальными автоуведомлениями по СМС или Email о днях рождения, истекающих абонементах, выполненных задачах и пр.",
            image: require("../../images/icons/mobile_notification.svg"),
          },
          {
            name: "Возвращайте клиентов",
            text: "Напомните “спящим” клиентам о себе, отправив сезонную акцию или специальное предложение.",
            image: require("../../images/icons/mobile_sleeping-customers.svg"),
          },
          {
            name: "Увеличьте вовлеченность",
            text: "Оформите предварительную запись онлайн и получите 100% заполняемость ваших групп.",
            image: require("../../images/icons/mobile_involvement.svg"),
          },
        ]} />
      <BannerComponent color={"green"}>
        Используйте Параплан в&nbsp;любом&nbsp;месте.<br />
        Все что нужно для начала работы&nbsp;&mdash;&nbsp;доступ&nbsp;в&nbsp;интернет
      </BannerComponent>
      <FeaturesComponent title="Дополнительные возможности" current={"mobile"} />
      <ContactFormComponent />
      <FeatureAccordion
            data={mobileAccordionData}
            title='Мобильная CRM-система для работы со смартфона'
            descr='Быстрая, удобная, адаптированная под разные устройства версия CRM-системы доступна в любой момент. Для работы требуется только Интернет. Мгновенно заносите полученную информацию о лиде или клиенте в его карточку. Не нужно ничего записывать или записывать в заметки. Через мобильную версию руководитель детского центра следит за ходом продаж, собирает отчеты, просматривает посещаемость занятий и контролирует скорость обработки заявок удаленно. Управление детским центром возможно с одним смартфоном в руке, если вы используете CRM Параплан. Ведь CRM с мобильной версией - это ваш офис в кармане.
            '
      />
    </Layout>
  )
}

export default MobileFeaturePage
